import React,{useState,useEffect} from "react";
import {Helmet} from 'react-helmet';
import './style.css';
import Layout from '../../components/layout/layout';
import eng1 from '../../resources/images/posts/eng1.jpg'
import eng2 from '../../resources/images/posts/eng2.jpg'
import eng3 from '../../resources/images/posts/eng3.jpg'
import urd1 from '../../resources/images/posts/urd1.jpg'
import urd2 from '../../resources/images/posts/urd2.jpg'
import urd3 from '../../resources/images/posts/urd3.jpg'
import meme1 from '../../resources/images/posts/meme1.jpg'
import meme2 from '../../resources/images/posts/meme2.jpg'
import meme3 from '../../resources/images/posts/meme3.jpg'
import meme4 from '../../resources/images/posts/meme4.jpg'
import meme5 from '../../resources/images/posts/meme5.jpg'
import rand1 from '../../resources/images/posts/rand1.jpg'
import rand2 from '../../resources/images/posts/rand2.jpg'
import rand3 from '../../resources/images/posts/rand3.jpg'
import rand4 from '../../resources/images/posts/rand4.jpg'
import rand5 from '../../resources/images/posts/rand5.jpg'

export default function Contact() {
  const [engPostsUI,setEngPostUI] = useState(null)
  const [urdPostsUI,setUrdPostUI] = useState(null)
  const [memePostsUI,setMemePostUI] = useState(null)
  const [randPostsUI,setRandPostUI] = useState(null)
  useEffect(() =>{
    const engPostArray = [eng1,eng2,eng3].map(x => {
      console.log(x);
      return(<div className='contentBox'>
      <img src={x} alt='eng-post' />

      </div>)
    });
    const urdPostsArray = [urd1,urd2,urd3].map(x => {
      return(<div className='contentBox'>
      <img src={x} alt='urd-post' />
      </div>)
    });
    const memesArray = [meme1,meme2,meme3,meme4,meme5].map(x => {
      return(<div className='contentBox'>
      <img src={x} alt='meme-post' />
      </div>)
    });
    const randPostsArray = [rand1,rand2,rand3,rand4,rand5].map(x => {
      return(<div className='contentBox'>
      <img src={x} alt='special-post' />
      </div>)
    });
    setEngPostUI(engPostArray);
    setUrdPostUI(urdPostsArray);
    setMemePostUI(memesArray);
    setRandPostUI(randPostsArray);
  },[])
  return(
    <Layout>
<div className='contentMain'>
<Helmet>
  <meta charSet='utf-8' />
  <title>Ansarulmomineen| Content</title>
</Helmet>
<section className='  head'>
  <h1 className='sectionHeadingGlobal'>Our Content</h1>
  </section>
  <section className='contentSection videos '>
  <h3>Videos</h3>
  <div className='contentContainer'>
  <div className='contentBox'>
  <iframe width='320' height='180' src="https://www.youtube.com/embed/ezE2cXweWHM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
    <div className='contentBox'>
  <iframe width="320" height="180" src="https://www.youtube.com/embed/b5VypGvbOMo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <div className='contentBox'>
  <iframe width="320" height="180" src="https://www.youtube.com/embed/IAjt2mQm9Wg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  </div>
<a href="https://www.youtube.com/channel/UCRbtoIb6q8nACM2P3N39b_w"><button className='morebtn'>More</button></a>
  </section>
  <section className='contentSection engPosts'>
  <h3>English hadees</h3>
  <div className='contentContainer'>
{engPostsUI}
  </div>
<a href="https://www.instagram.com/ansarulmomineen/"><button className='morebtn'>More</button></a>
  </section>
  <section className='contentSection urduPosts'>
  <h3>Urdu hadees</h3>
  <div className='contentContainer'>
{urdPostsUI}
  </div>
<a href="https://www.facebook.com/ansarulmomineen/"><button className='morebtn'>More</button></a>
  </section>
  <section className='contentSection memes'>
  <h3>Memes</h3>
  <div className='contentContainer'>
{memePostsUI}
  </div>
<a href="https://www.twitter.com/ansarulmomineen/"><button className='morebtn'>More</button></a>
  </section>
  <section className='contentSection specials'>
  <h3>Special Posts</h3>
  <div className='contentContainer'>
{randPostsUI}
  </div>
<a href="https://www.instagram.com/ansarulmomineen/"><button className='morebtn'>More</button></a>
  </section>
</div>
    </Layout>
  )
}
